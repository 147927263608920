$primary: #ffc90c;
$secondary: #003b75;
$white: #fff;
$grey: #383838;
$darkGrey: #42484d;
$danger: #e02e2e;
$danger2: #d3465a;
// $backgroundPrimary: hsl(210, 13%, 6%);
$backgroundPrimary: hsl(208, 26%, 9%);
$backgroundSecondary: lighten($backgroundPrimary, 6.5);
$backgroundTertiary: lighten($backgroundPrimary, 12.5);

$font: 'Open Sans';

$textGrey: #9b9b9b;
$textWhite: #fff;

$asideWidth: 300px;
$mainWidth: 720px;

$editModalWidth: 760px;
