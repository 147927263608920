.app-input {
	display: flex;
	align-items: flex-start;
	.app-square-icon {
		margin-right: 2px;
		svg {
			fill: $primary;
		}
	}
	&.invalid {
		.app-input--element {
			border-color: $danger;
		}
	}
	.app-square-icon {
		margin: 0;
		position: relative;
		min-width: 55px;
		background: $backgroundTertiary;
		box-shadow: none;
		border-radius: 0;
		&:before {
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			transform: skewX(-6deg);
			background: $backgroundSecondary;
			pointer-events: none;
		}
	}
	&.textarea .app-square-icon:before {
		transform: none;
	}
}

.app-input--container {
	flex-grow: 1;
}

.app-input--container.error {
	.app-input--element {
		border-color: $danger;
	}
}

.app-input--element {
	width: 100%;
	font: 400 14px $font, Sans-serif;
	color: $textWhite;
	// border: 1px solid rgba(#fff, .15);
	background: $backgroundTertiary;
	border-radius: 0;
	padding: 15.5px 20px;
	transition: border-color .2s ease;
}

textarea.app-input--element {
	min-height: 120px;
	max-height: 600px;
}

.app-input--message {
	color: $danger;
	font: 500 14px/20px $font, Sans-serif;
	margin: 6px 0 0;
	text-align: right;
	&.note {
		color: #9b9b9b;
		font-size: 13px;
		font-weight: 600;
	}
}

.app-input--price {
	.app-input--container {
		width: 140px;
		position: relative;
	}
	&.has-value {
		.app-input--element {
			padding-left: 32px;
		}
	}
	.app-input--prefix-container {
		position: absolute;
		padding: 15px 20px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 1px solid transparent;
		pointer-events: none;
		p {
			font: 400 14px $font, Sans-serif;
			span {
				opacity: 0;
			}
		}
	}
	&:not(.is-small) {
		.app-input--element {
			border: none;
			background: none;
			box-shadow: none;
			text-align: center;
			padding: 22px;
			font-size: 34px;
		}
		&.has-value {
			.app-input--element {
				padding-left: 22px + 24px;
			}
		}
		.app-input--message {
			pointer-events: none;
			position: absolute;
			bottom: 2px;
			right: 2px;
			background: rgba($danger, .1);
			padding: 1px 5px;
			border-radius: 4px;
			font-weight: 500;
		}
		.app-input--prefix-container {
			border: none;
			padding: 22px;
			text-align: center;
			p {
				font: 400 34px $font, Sans-serif;
			}
		}
	}
}

.app-input--legend {
	&::before {
		display: none !important;
	}
}

.app-input--legend-note {
	font: 500 15px $font, Sans-serif;
	margin: 10px 0 0;
	text-align: right;
	span {
		color: $primary;
		padding: 2px;
		background: rgba($primary, .1);
		font-weight: 600;
		border-radius: 4px;
	}
}
